import React from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";
import Cookies from "js-cookie";
const renderMergedProps = (component, ...rest) => {
  const finalProps = Object.assign({}, ...rest);
  return React.createElement(component, finalProps);
};

const TOKEN_KEY = 'auth_token';

function isLogin() {
  if (Cookies.get(TOKEN_KEY)) {
    console.log("logged")
      return true;
  }
  console.log("called")
  return false;
}


const PrivateRoute = ({ component, ...rest }) => (
  isLogin() ?
    <Route
      {...rest}
      render={routeProps => renderMergedProps(component, routeProps, rest)}
    />
  : <Redirect to="/home" />
);

PrivateRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.elementType, PropTypes.node])
};

export default PrivateRoute;