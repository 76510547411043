import React, { Fragment, Suspense, lazy } from "react";
import {
  ThemeProvider,
  StyledEngineProvider,
  CssBaseline,
} from "@mui/material";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import theme from "./theme";
import GlobalStyles from "./GlobalStyles";
import Pace from "./shared/components/Pace";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import PrivateRoute from "./shared/components/PrivateRoute";
import "./App.css"
import Cookies from 'js-cookie';

const LoggedInComponent = lazy(() => import("./logged_in/components/Main"));
const LoggedOutComponent = lazy(() => import("./logged_out/components/Main"));
const client = new QueryClient();

function App() {

  const TOKEN_KEY = 'auth_token';

  return (
    <QueryClientProvider client={client}>
      <BrowserRouter>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <GlobalStyles />
            <Pace color={theme.palette.primary.light} />
            <Suspense fallback={<Fragment />}>
              <Switch>
                <Route exact path = "/">
                  <Redirect to = "/confab" />
                </Route>
                <PrivateRoute path="/confab" component={LoggedInComponent} />
                {!Cookies.get(TOKEN_KEY) ? <Route path = "/home">
                  <LoggedOutComponent />
                </Route> : 
                <Redirect to = "/confab" />
                }
              </Switch>
            </Suspense>
          </ThemeProvider>
        </StyledEngineProvider>
      </BrowserRouter>
      <ReactQueryDevtools />
    </QueryClientProvider>
  );
}

export default App;
